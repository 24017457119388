/**
 * 员工消耗业绩api
 */
 import * as API from '@/api/index'

 export default {
    // 营业报表-实收统计
    getEmployeeTreatPerformanceCommissionDetail: params => {
        return API.POST('api/employeeTreatPerformanceCommissionDetailStatement/list', params)
    },
    // 54.10.员工消耗业绩提成明细导出
    exportemployeeTreatPerformanceCommissionDetailStatementt: params => {
      return API.exportExcel('api/employeeTreatPerformanceCommissionDetailStatement/excel', params)
    },

 }