<template>
  <div class="content_body" v-loading="loading">
    <div class="nav_header" style="padding: 0px">
      <el-form :inline="true" size="small" :model="searchTreatPerformanceCommissionDetailData" @submit.native.prevent>
        <el-form-item v-if="storeEntityList.length > 1" label="开单门店">
          <el-select v-model="searchTreatPerformanceCommissionDetailData.EntityID" clearable filterable placeholder="请选择门店" :default-first-option="true" @change="handleTreatPerformanceCommissionSearch">
            <el-option v-for="item in storeEntityList" :key="item.ID" :label="item.EntityName" :value="item.ID"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间筛选">
          <el-date-picker v-model="searchTreatPerformanceCommissionDetailData.QueryDate" unlink-panels type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" @change="handleTreatPerformanceCommissionSearch"></el-date-picker>
        </el-form-item>
        <el-form-item label="卡类型">
          <el-select v-model="searchTreatPerformanceCommissionDetailData.TreatCardTypeName" clearable filterable placeholder="请选择卡类型" :default-first-option="true" @change="handleTreatPerformanceCommissionSearch">
            <el-option label="项目卡" value="项目卡"></el-option>
            <el-option label="储值卡" value="储值卡"></el-option>
            <el-option label="时效卡" value="时效卡"></el-option>
            <el-option label="通用次卡" value="通用次卡"></el-option>
            <el-option label="产品卡" value="产品卡"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="员工姓名">
          <el-input v-model="searchTreatPerformanceCommissionDetailData.EmployeeName" clearable @keyup.enter.native="handleTreatPerformanceCommissionSearch" @clear="handleTreatPerformanceCommissionSearch" placeholder="请输入员工姓名"></el-input>
        </el-form-item>
        <el-form-item label="卡名称">
          <el-input v-model="searchTreatPerformanceCommissionDetailData.CardName" clearable @keyup.enter.native="handleTreatPerformanceCommissionSearch" @clear="handleTreatPerformanceCommissionSearch" placeholder="请输入员工姓名"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-prevent-click @click="handleTreatPerformanceCommissionSearch">搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button v-if="treatPerformanceCommissionDetailExport" type="primary" size="small" :loading="downloadLoading" @click="downloadExcel">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table size="small" show-summary :summary-method="getTreatPerformanceCommissionDetailListSummaries" :data="TreatPerformanceCommissionDetailList" v-loading="TreatPerformanceCommissionDetailLoading">
      <el-table-column prop="TreatBillID" label="订单编号"></el-table-column>
      <el-table-column prop="BillDate" label="下单日期"></el-table-column>
      <el-table-column prop="EntityName" label="下单门店"></el-table-column>
      <el-table-column prop="EmployeeName" label="员工"></el-table-column>
      <el-table-column prop="EmployeeName" label="会员/编号">
        <template slot-scope="scope">
          <span
            >{{ scope.row.CustomerName }}
            <span v-if="scope.row.CustomerCode">({{ scope.row.CustomerCode }})</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="TreatCardTypeName" label="卡类型"></el-table-column>
      <el-table-column prop="CardName" label="卡名称"></el-table-column>
      <el-table-column prop="CategoryName" label="商品类别"></el-table-column>
      <el-table-column prop="GoodName" label="商品名"></el-table-column>
      <el-table-column align="right" prop="Performance" label="本金业绩">
        <template align="right" slot-scope="scope">
          <div v-if="scope.row.Performance < 0" class="color_red">
            {{ scope.row.Performance | NumFormat }}
          </div>
          <div v-else-if="scope.row.Performance > 0" class="color_green">+{{ scope.row.Performance | NumFormat }}</div>
          <div v-else>0.00</div>
        </template>
      </el-table-column>
      <el-table-column align="right" prop="PerformanceCommission" label="本金提成">
        <template align="right" slot-scope="scope">
          <div v-if="scope.row.PerformanceCommission < 0" class="color_red">
            {{ scope.row.PerformanceCommission | NumFormat }}
          </div>
          <div v-else-if="scope.row.PerformanceCommission > 0" class="color_green">+{{ scope.row.PerformanceCommission | NumFormat }}</div>
          <div v-else>0.00</div>
        </template>
      </el-table-column>
      <el-table-column align="right" prop="LargessPerformance" label="赠金业绩">
        <template align="right" slot-scope="scope">
          <div v-if="scope.row.LargessPerformance < 0" class="color_red">
            {{ scope.row.LargessPerformance | NumFormat }}
          </div>
          <div v-else-if="scope.row.LargessPerformance > 0" class="color_green">+{{ scope.row.LargessPerformance | NumFormat }}</div>
          <div v-else>0.00</div>
        </template>
      </el-table-column>
      <el-table-column align="right" prop="LargessPerformanceCommission" label="赠金提成">
        <template align="right" slot-scope="scope">
          <div v-if="scope.row.LargessPerformanceCommission < 0" class="color_red">
            {{ scope.row.LargessPerformanceCommission | NumFormat }}
          </div>
          <div v-else-if="scope.row.LargessPerformanceCommission > 0" class="color_green">+{{ scope.row.LargessPerformanceCommission | NumFormat }}</div>
          <div v-else>0.00</div>
        </template>
      </el-table-column>
      <el-table-column align="right" prop="SpecialBenefitCommission" label="特殊补贴">
        <template align="right" slot-scope="scope">
          <div v-if="scope.row.SpecialBenefitCommission < 0" class="color_red">
            {{ scope.row.SpecialBenefitCommission | NumFormat }}
          </div>
          <div v-else-if="scope.row.SpecialBenefitCommission > 0" class="color_green">+{{ scope.row.SpecialBenefitCommission | NumFormat }}</div>
          <div v-else>0.00</div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pad_15 text_right">
      <el-pagination
        background
        v-if="TreatPerformanceCommissionDetailPaginations.total > 0"
        @current-change="handleTreatPerformanceCommissionPageChange"
        :current-page.sync="TreatPerformanceCommissionDetailPaginations.page"
        :page-size="TreatPerformanceCommissionDetailPaginations.page_size"
        :layout="TreatPerformanceCommissionDetailPaginations.layout"
        :total="TreatPerformanceCommissionDetailPaginations.total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import EntityAPI from "@/api/Report/Common/entity";
import API from "@/api/Report/Employee/treatPerformanceCommissionDetailStatement";
import permission from "@/components/js/permission.js";

export default {
  name: "EmployeeTreatPerformanceCommissionDetail",
  data() {
    return {
      downloadLoading: false,
      loading: false,
      storeEntityList: [], //门店列表
      searchTreatPerformanceCommissionDetailData: {
        EntityID: null,
        QueryDate: [new Date(), new Date()],
        TreatCardTypeName: "",
        EmployeeName: "",
        CardName: "",
      },
      TreatPerformanceCommissionDetailList: [],
      TreatPerformanceCommissionDetailSum: {},
      TreatPerformanceCommissionDetailLoading: false,
      //需要给分页组件传的信息
      TreatPerformanceCommissionDetailPaginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      treatPerformanceCommissionDetailExport: false,
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.treatPerformanceCommissionDetailExport = permission.permission(to.meta.Permission, "Report-Employee-TreatPerformanceCommissionDetail-Export");
    });
  },
  methods: {
    //获得当前用户下的权限门店
    getstoreEntityList() {
      var that = this;
      that.loading = true;
      EntityAPI.getStoreEntityList()
        .then((res) => {
          if (res.StateCode == 200) {
            that.storeEntityList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },

    handleTreatPerformanceCommissionSearch() {
      var that = this;
      that.TreatPerformanceCommissionDetailPaginations.page = 1;
      that.TreatPerformanceCommissionDetail();
    },
    handleTreatPerformanceCommissionPageChange(page) {
      this.TreatPerformanceCommissionDetailPaginations.page = page;
      this.TreatPerformanceCommissionDetail();
    },
    // 销售搜索
    TreatPerformanceCommissionDetail() {
      var that = this;
      if (that.searchTreatPerformanceCommissionDetailData.QueryDate != null) {
        var params = {
          EntityID: that.searchTreatPerformanceCommissionDetailData.EntityID,
          StartDate: that.searchTreatPerformanceCommissionDetailData.QueryDate[0],
          //   StartDate: "2020-03-02",
          EndDate: that.searchTreatPerformanceCommissionDetailData.QueryDate[1],
          TreatCardTypeName: that.searchTreatPerformanceCommissionDetailData.TreatCardTypeName.trim(),
          EmployeeName: that.searchTreatPerformanceCommissionDetailData.EmployeeName.trim(),
          PageNum: that.TreatPerformanceCommissionDetailPaginations.page,
          CardName: that.searchTreatPerformanceCommissionDetailData.CardName, //卡名称搜索
        };
        that.TreatPerformanceCommissionDetailLoading = true;
        API.getEmployeeTreatPerformanceCommissionDetail(params)
          .then((res) => {
            if (res.StateCode == 200) {
              that.TreatPerformanceCommissionDetailSum = res.Data.employeeTreatPerformanceCommissionSumStatementForm;
              that.TreatPerformanceCommissionDetailList = res.Data.employeeTreatPerformanceCommissionDetailStatementForms.List;
              that.TreatPerformanceCommissionDetailPaginations.total = res.Data.employeeTreatPerformanceCommissionDetailStatementForms.Total;
              that.TreatPerformanceCommissionDetailPaginations.page_size = res.Data.employeeTreatPerformanceCommissionDetailStatementForms.PageSize;
            } else {
              that.$message.error({
                message: res.Message,
                duration: 2000,
              });
            }
          })
          .finally(function() {
            that.TreatPerformanceCommissionDetailLoading = false;
          });
      }
    },
    getTreatPerformanceCommissionDetailListSummaries() {
      const sums = [];
      sums[0] = <span class="font_weight_600">合计</span>;
      sums[1] = "";
      sums[2] = "";
      sums[3] = "";
      sums[4] = "";
      sums[5] = "";
      sums[6] = "";
      sums[7] = "";

      if (!this.TreatPerformanceCommissionDetailSum) {
        sums[8] = "";
        sums[9] = "";
        sums[10] = "";
        sums[11] = "";
        sums[12] = "";
        return sums;
      }

      var filter_NumFormat = this.$options.filters["NumFormat"];
      sums[9] = <span class="font_weight_600">{filter_NumFormat(this.TreatPerformanceCommissionDetailSum.Performance)}</span>;
      sums[10] = <span class="font_weight_600">{filter_NumFormat(this.TreatPerformanceCommissionDetailSum.PerformanceCommission)}</span>;
      sums[11] = <span class="font_weight_600">{filter_NumFormat(this.TreatPerformanceCommissionDetailSum.LargessPerformance)}</span>;
      sums[12] = <span class="font_weight_600">{filter_NumFormat(this.TreatPerformanceCommissionDetailSum.LargessPerformanceCommission)}</span>;
      sums[13] = <span class="font_weight_600">{filter_NumFormat(this.TreatPerformanceCommissionDetailSum.SpecialBenefitCommission)}</span>;
      return sums;
    },
    /** 数据导出 */
    downloadExcel() {
      var that = this;
      let params = {
        EntityID: that.searchTreatPerformanceCommissionDetailData.EntityID,
        StartDate: that.searchTreatPerformanceCommissionDetailData.QueryDate[0],
        EndDate: that.searchTreatPerformanceCommissionDetailData.QueryDate[1],
        TreatCardTypeName: that.searchTreatPerformanceCommissionDetailData.TreatCardTypeName.trim(),
        EmployeeName: that.searchTreatPerformanceCommissionDetailData.EmployeeName.trim(),
        CardName: that.searchTreatPerformanceCommissionDetailData.CardName, //卡名称搜索
      };
      that.downloadLoading = true;
      API.exportemployeeTreatPerformanceCommissionDetailStatementt(params)
        .then((res) => {
          this.$message.success({
            message: "正在导出",
            duration: "4000",
          });
          const link = document.createElement("a");
          let blob = new Blob([res], { type: "application/octet-stream" });
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.download = "消耗业绩提成明细.xlsx"; //下载的文件名
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .finally(function() {
          that.downloadLoading = false;
        });
    },
  },
  mounted() {
    var that = this;
    that.treatPerformanceCommissionDetailExport = permission.permission(that.$route.meta.Permission, "Report-Employee-TreatPerformanceCommissionDetail-Export");
    console.log("🚀 ~ file: treatPerformanceCommissionDetail.vue ~ line 281 ~ mounted ~ that.treatPerformanceCommissionDetailExport", that.treatPerformanceCommissionDetailExport);
    that.getstoreEntityList();
    that.handleTreatPerformanceCommissionSearch();
  },
};
</script>
